import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-readonly-field',
  templateUrl: './readonly-field.component.html',
  styleUrls: ['./readonly-field.component.scss'],
})
export class ReadonlyFieldComponent implements OnInit {

  @Input() name = "Имя";
  @Input() value = "Лев";

  constructor() { }

  ngOnInit() {}

}
