import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Injectable,
  Injector,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TextService {
  readonly apiUrl = `${environment.mobileApi}api/text/`;

  constructor(private http: HttpClient) {}

  public get(key: string) {
    return this.http.get<any>(`${this.apiUrl + key}`);
  }
}

@Pipe({
  name: 'lText',
})
export class LoadTextPipe implements PipeTransform {
  constructor(private textService: TextService) {}

  transform(key: string): Observable<string> {
    return this.textService.get(key).pipe(map((x) => x.text));
  }
}
