import { Injectable } from "@angular/core";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class AvatarService {
  constructor(private userService: UserService) {}

  getAvatar() {
    let user = this.userService.getUserData();
    return (
      "https://1c-api.uni-dubna.ru/v1/api/persons/GetPersonPhotoByCode/" +
      user.employeeId
    );
  }
}
